<template>
  <div class="pa-4">
    <v-card
      v-for="question in questions"
      :id="`question-${question.id}`"
      :key="question.id"
      class="my-2"
      flat
      :outlined="currentQuestion.id === question.id"
      :disabled="currentQuestion.id !== question.id"
    >
      <v-card-text>
        <v-row>
          <v-col>
            <span>{{ question.title || '' }}</span>
          </v-col>
        </v-row>

        <v-row no-gutters justify="center" class="my-3">
          <question-open
            v-if="question.typeOfQuestion.key === 'OPEN'"
            :key="question.id"
            v-model="answers"
            :question="question"
            :validation="validation[question.id]"
          />
          <question-close
            v-if="question.typeOfQuestion.key === 'CLOSE'"
            :key="question.id"
            v-model="answers"
            :question="question"
            :validation="validation[question.id]"
            @change="onChangeQuestionClose"
          />
          <question-table
            v-if="question.typeOfQuestion.key === 'TABLE'"
            :key="question.id"
            v-model="answers"
            :question="question"
            :validation="validation[question.id]"
          />
        </v-row>

        <v-row v-if="currentQuestion && currentQuestion.id === question.id">
          <v-col>
            <v-btn
              color="secondary"
              class="mr-2"
              small
              :disabled="isFirstQuestion || loading"
              @click="goToPreviousQuestion()"
            >
              <v-icon left v-text="'mdi-arrow-left'" />
              Anterior
            </v-btn>
            <v-btn
              v-if="!isLastQuestion && !forceEnd"
              color="primary"
              small
              :loading="loadingSaveAnswer"
              @click="goToNextQuestion(question)"
            >
              Próxima
              <v-icon right v-text="'mdi-arrow-right'" />
            </v-btn>
            <v-btn
              v-if="isLastQuestion || forceEnd"
              color="success"
              small
              :disabled="loading"
              :loading="loadingSaveAnswer"
              @click="goToNextQuestion(question, true)"
            >
              Finalizar
              <v-icon right v-text="'mdi-arrow-right'" />
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import answerSurveyApi from '@/api/answer-surveys'

export default {
  components: {
    QuestionOpen: () => import('./QuestionOpen'),
    QuestionClose: () => import('./QuestionClose'),
    QuestionTable: () => import('./QuestionTable'),
  },
  data: () => ({
    loadingSaveAnswer: false,
    validation: {},
    answers: {},
    forceEnd: false,
    disabledNextButton: false,
  }),

  mounted() {
    if (this.currentQuestion && this.flow.length <= 0) {
      this.addQuestionFlow(this.currentQuestion.id)
    }
    this.fillAnswers()
    // this.checkIfItemEndSurvey()
  },

  watch: {
    indexQuestion: {
      immediate: true,
      handler() {
        this.scrollToCurrentQuestion()
        this.checkIfQuestionEndSurvey()
      },
    },
  },

  computed: {
    ...mapState({
      loading: state => state.answerSurvey.loading,
      flow: state => state.answerSurvey.flow,
      savedAnswers: state => state.answerSurvey.answers,
      indexQuestion: state => state.answerSurvey.indexQuestion,
    }),
    ...mapGetters({
      questions: 'answerSurvey/questions',
      currentQuestion: 'answerSurvey/currentQuestion',
      isFirstQuestion: 'answerSurvey/isFirstQuestion',
      isLastQuestion: 'answerSurvey/isLastQuestion',
      getQuestionAnswer: 'answerSurvey/getQuestionAnswer',
    }),
  },

  methods: {
    ...mapActions('answerSurvey', [
      'saveAnswer',
      'nextQuestion',
      'previousQuestion',
    ]),

    ...mapMutations({
      addQuestionFlow: 'answerSurvey/ADD_QUESTION_FLOW',
    }),

    async goToNextQuestion(question, end = false) {
      try {
        this.loadingSaveAnswer = true
        let answer = {}
        this.validation = {}

        if (question.typeOfQuestion.key === 'TABLE') {
          answer = { answer: this.answers[question.id] }
        } else {
          answer = { ...this.answers[question.id] }
        }

        const response = await answerSurveyApi.saveAnswer(
          this.$route.params.surveyId,
          question.id,
          answer,
        )

        for (const answer of response.data.answers) {
          this.saveAnswer(answer)
        }
        this.nextQuestion(answer)

        if (end) {
          this.$router.push(
            `/responder-pesquisa/${this.$route.params.surveyId}/finalizar`,
          )
        }
      } catch (e) {
        console.log(e)
        if (e.response.status === 422) {
          this.validation[question.id] = this.$apiError.fieldsValidation(e)
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSaveAnswer = false
      }
    },

    async goToPreviousQuestion() {
      this.previousQuestion()
    },

    scrollToCurrentQuestion() {
      setTimeout(() => {
        document
          .getElementById(`question-${this.currentQuestion.id}`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
      }, 300)
    },

    checkIfItemEndSurvey() {
      this.forceEnd = false

      if (
        this.currentQuestion.typeOfQuestion.key !== 'CLOSE' ||
        this.currentQuestion.nextQuestionId ||
        this.currentQuestion.multipleChoice
      ) {
        return
      }

      const item = this.currentQuestion.items.find(
        i => i.id === this.answers[this.currentQuestion.id]?.answer,
      )

      if (item) this.forceEnd = !item.nextQuestionId
    },

    checkIfQuestionEndSurvey() {
      this.forceEnd = false

      if (
        this.currentQuestion.typeOfQuestion.key === 'CLOSE' ||
        this.currentQuestion.nextQuestionId
      ) {
        return
      }

      this.forceEnd = true
    },


    onChangeQuestionClose() {
      this.checkIfItemEndSurvey()
    },

    fillAnswers() {
      const keys = Object.keys(this.questions)
      keys.forEach(key => {
        const question = this.questions[key]
        const answer = this.getQuestionAnswer(question.id)

        if (answer.length <= 0) return

        if (!this.answers[question.id]) {
          this.answers[question.id] = {}
        }

        if (question.typeOfQuestion.key === 'OPEN') {
          this.answers[question.id].answer = answer[0].text
        } else if (question.typeOfQuestion.key === 'CLOSE') {
          if (question.multipleChoice) {
            this.answers[question.id].answer = answer.map(a => a.questionItemId)
          } else {
            this.answers[question.id].answer = answer[0].questionItemId
            if (answer[0].text) {
              this.answers[question.id].explain = answer[0].text
            }
          }
        } else if (question.typeOfQuestion.key === 'TABLE') {
          this.answers[question.id] = JSON.parse(answer[0].text)
        }
      })

      this.onChangeQuestionClose()
    },
  },
}
</script>
